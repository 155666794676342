<template>
  <div>
    <v-select
      :key="`${childName}-${keyName}`"
      :value="records"
      :items="children"
      :item-value="getId"
      return-object
      multiple
      hide-selected
      :menu-props="{ auto: true, offsetY: true }"
      :label="label"
      :error="error"
      :error-messages="errorMessages"
      no-data-text="選択可能なデータがありません"
      @change="onSelect($event)"
    >
      <template #selection="{ attrs, item }">
        <v-chip
          v-bind="attrs"
          close
          :color="isToDestroy(item.id) ? 'error' : ''"
          :class="isToDestroy(item.id) ? 'v-chip-light-bg error--text' : ''"
          @click:close="onRemove(item)"
        >
          <v-avatar
            v-if="('profileImage') in item.attributes"
            left
          >
            <v-img
              v-if="item.attributes.profileImage"
              :src="item.attributes.profileImage"
            />
            <v-icon v-else>
              {{ icons.mdiAccountCircleOutline }}
            </v-icon>
          </v-avatar>

          {{ itemText(item) }}
        </v-chip>
      </template>

      <template #item="{ item }">
        <template v-if="!item.attributes && item.header">
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <v-list-item-content v-text="item.header" />
        </template>

        <v-list-item-action>
          <v-checkbox
            :ripple="false"
            hide-details
            disabled
          />
        </v-list-item-action>

        <v-list-item-avatar
          v-if="('profileImage') in item.attributes"
          size="32"
          class="mr-2"
        >
          <v-img
            v-if="item.attributes.profileImage"
            :src="item.attributes.profileImage"
          />
          <v-icon v-else>
            {{ icons.mdiAccountCircleOutline }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ itemText(item) }}
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <template #append-outer>
        <slot name="append-outer" />
      </template>
    </v-select>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  chain,
  filter,
  includes,
  map,
  findIndex,
  find,
  xor,
  flatMap,
  values,
  join,
  cloneDeep,
} from 'lodash'
import { mdiAccountCircleOutline } from '@mdi/js'
import { useVModel } from '@vueuse/core'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    parentId: {
      type: [Number, String],
      default: null,
    },
    parentName: {
      type: String,
      default: '',
      required: true,
    },
    childName: {
      type: String,
      default: '',
      required: true,
    },
    children: {
      type: Array,
      default: () => [],
      required: true,
    },
    childType: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: Function,
      default: item => item.attributes.name,
    },
  },
  setup(props) {
    const records = useVModel(props, 'value')
    const keyName = ref(Math.random())

    const recordIdsToDestroy = computed(() => {
      const destroyTargets = filter(records.value, '_destroy')

      return map(destroyTargets, record => Number(record[`${props.childName}Id`]))
    })

    const setKeyName = () => {
      keyName.value = chain(props.value).flatMap(o => values(o)).join('').value()
    }

    const isToDestroy = childNameId => {
      return includes(recordIdsToDestroy.value, Number(childNameId))
    }

    const getId = obj => {
      let id
      switch (obj.type) {
        case props.childType || props.childName:
          // eslint-disable-next-line prefer-destructuring
          id = obj.id
          break

        default:
          id = obj[`${props.childName}Id`]
          break
      }

      return Number(id)
    }

    const onSelect = incomingItems => {
      const recordsChildNameIds = map(records.value, record => Number(record[`${props.childName}Id`]))
      const incomingItemsIds = map(incomingItems, item => getId(item))
      const newItemIdToAdd = xor(recordsChildNameIds, incomingItemsIds)[0]

      const newRecord = {}
      newRecord[`${props.parentName}Id`] = props.parentId
      newRecord[`${props.childName}Id`] = newItemIdToAdd

      records.value.push(newRecord)
    }

    const onRemove = item => {
      const removeTargetIdx = findIndex(
        records.value,
        record => Number(record[`${props.childName}Id`]) === Number(item.id),
      )
      const removeTarget = records.value[removeTargetIdx]

      if (removeTarget?.id) {
        // eslint-disable-next-line no-underscore-dangle
        removeTarget._destroy = !removeTarget._destroy
        records.value.splice(removeTargetIdx, 1, removeTarget)
      } else {
        records.value.splice(removeTargetIdx, 1)
      }

      setKeyName()

      // NOTE: useVModelはspliceだと反応しないので
      records.value = cloneDeep(records.value)
    }

    return {
      // data
      records,
      keyName,

      // computed
      recordIdsToDestroy,

      // methods
      isToDestroy,
      getId,
      onSelect,
      onRemove,

      icons: {
        mdiAccountCircleOutline,
      },
    }
  },
}
</script>
